
@import "~bootstrap-icons/font/bootstrap-icons.css";

a{
  color: var(--text-color);
  text-decoration: none;
}

a:hover{
  color: var(--main-color);
}

.icon {
  font-family: "Bootstrap Icons";
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}


.my-icon::before {
  content: "\f101"; /* Codul unic pentru icona "bi-chevron-right" */
  
}


.about-me .content h3 {
   font-weight: 700;
   font-size: 26px;
   color: #0ef;
 }
 
 .about-me .content ul {
   list-style: none;
   padding: 0;
 }
 
 .about-me .content ul li {
   margin-bottom: 20px;
   display: flex;
   align-items: center;
 }
 
 .about-me .content ul strong {
   margin-right: 10px;
 }
 
 .about-me .content ul i {
   font-size: 16px;
   margin-right: 5px;
   color: #0ef;
   line-height: 0;
 }
 
 .about-me .content p:last-child {
   margin-bottom: 0;
 }
 
 
 /*------------------------
# Skills
-------------------------*/
.skills .progress {
   height: 60px;
   display: block;
   background: none;
   border-radius: 0;
 }
 
 .skills .progress .skill {
   padding: 10px 0;
   margin: 0;
   text-transform: uppercase;
   display: block;
   font-weight: 600;
   font-family: "Poppins", sans-serif;
   color: #fff;
 }
 
 .skills .progress .skill .val {
   float: right;
   font-style: normal;
 }
 
 .skills .progress-bar-wrap {
   background: rgba(255, 255, 255, 0.2);
 }
 
 .skills .progress-bar {
   width: 1px;
   height: 10px;
   transition: 0.9s;
   background-color: #0ef;
 }
 
 /*---------------------
 # Interests
 ----------------------*/
 .interests .icon-box {
   display: flex;
   align-items: center;
   padding: 20px;
   background: rgba(255, 255, 255, 0.08);
   transition: ease-in-out 0.3s;
 }
 
 .interests .icon-box i {
   font-size: 32px;
   padding-right: 10px;
   line-height: 1;
 }
 
 .interests .icon-box h3 {
   font-weight: 700;
   margin: 0;
   padding: 0;
   line-height: 1;
   font-size: 16px;
   color: #fff;
 }
 
 .interests .icon-box:hover {
   background: rgba(255, 255, 255, 0.12);
 }
 


.custom-card:hover .btn-overlay {
  display: block; /* Afișați butonul la suprapunere */
}



.backgroundServices{
 background-color:  rgba(0, 0, 0, 1);
 border-radius: 10px;
 color: white;

}

.btn-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none; /* Butonul este ascuns inițial */
}

/* Stilizare buton */
.btn-overlay button {
  background-color:rgba(0, 0, 0, 0.9);
  color: #0ef;
  border: 2px solid #0ef;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 8px;
}


/* card */



.services-plan .card {
  border: none;
  border-radius: 1rem;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 1rem 0 rgba(3, 3, 3, 0.1);
  
}



.services-plan .btn {
  transition: all 0.2s;
}

/* Hover Effects on Card */


  .services-plan .card:hover {
    margin-top: -.85rem;
    margin-bottom: .25rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(3, 3, 3, 0.5);
  }

  
