/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
#portofoliu{
  animation: fadein 1s ease-in;
}

.portfolio .portfolio-item {
    margin-bottom: 15px;
  }
  
  .portfolio #portfolio-flters {
    padding: 0;
    margin: 0 auto 15px auto;
    list-style: none;
    text-align: center;
    border-radius: 50px;
    padding: 2px 15px;
  }
  
  .portfolio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 16px 10px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    color: black;
    background: rgba(255, 255, 255, 0.1);
    margin: 0 3px 10px 3px;
    transition: all 0.3s ease-in-out;
    border-radius: 4px;
  }


  
  .portfolio #portfolio-flters li:hover,
  .portfolio #portfolio-flters li.filter-active {
    background: #0ef;}
  
  
  .portfolio #portfolio-flters li:last-child {
    margin-right: 0;
  }
  
  .portfolio .portfolio-wrap {
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    z-index: 1;
    
  }
  
  
  
  
  
  .portfolio .portfolio-wrap::before {
    content: "";
    background: rgba(3, 3, 3, 0.9);
    position: absolute;
    left: 30px;
    right: 30px;
    top: 30px;
    bottom: 30px;
    transition: all ease-in-out 0.3s;
    z-index: 2;
    opacity: 0;
  }
  
  .portfolio .portfolio-wrap .portfolio-info {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: 3;
    transition: all ease-in-out 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }
  
  .portfolio .portfolio-wrap .portfolio-info::before {
    display: block;
    content: "";
    width: 48px;
    height: 48px;
    position: absolute;
    top: 35px;
    left: 35px;
    border-top: 3px solid #0ef;
    border-left: 3px solid #0ef;
    transition: all 0.5s ease 0s;
    z-index: 9994;
  }
  
  .portfolio .portfolio-wrap .portfolio-info::after {
    display: block;
    content: "";
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: 35px;
    right: 35px;
    border-bottom: 3px solid #0ef;
    border-right: 3px solid #0ef;
    transition: all 0.5s ease 0s;
    z-index: 9994;
  }
  
  .portfolio .portfolio-wrap .portfolio-info h4 {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    
  }
  
  .portfolio .portfolio-wrap .portfolio-info p {
    color: #ffffff;
    font-size: 14px;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
   
    
  }

  .bx , .bx-plus{ 
    font-size: 48px;
  }
  
  .portfolio .portfolio-wrap .portfolio-links {
    text-align: center;
    z-index: 4;
  }
  
  .portfolio .portfolio-wrap .portfolio-links a {
    color: #fff;
    margin: 0 2px;
    font-size: 48px;
    display: inline-block;
    transition: 0.3s;
  }
  
  .portfolio .portfolio-wrap .portfolio-links a:hover {
    color: #0ef;
  }
  
  .portfolio .portfolio-wrap:hover::before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
  }
  
  .portfolio .portfolio-wrap:hover .portfolio-info {
    opacity: 1;
  }
  
  .portfolio .portfolio-wrap:hover .portfolio-info::before {
    top: 15px;
    left: 15px;
  }
  
  .portfolio .portfolio-wrap:hover .portfolio-info::after {
    bottom: 15px;
    right: 15px;
  }

  .myModal {
    width: 100%;
    height: 100vh;
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .myModal .header {
    display: flex;
    position: absolute;
    top:0;
   
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px 50px;
    background-color: transparent;
  }


  
  .myModal img {
    width: 100%;
    height: 100%;
  }
  
  .myModal button {
    background-color: transparent;
    color: var(--text-color);
    border: none;
    width: 20px;
    cursor: pointer;
  }
  
  .myModal button:disabled {
    color: #999; /* Schimbați aici culoarea pentru butoanele dezactivate */
  }
  
  
  .myModal .close {
    font-size: 30px;
    cursor: pointer;
    color: var(--text-color);
  }
  

  @keyframes fadein {
    0% {
      opacity: 0;
      transform: translateY(0); /* Derulare în sus cu 20px la început */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* Opacitatea 1 și nicio derulare la sfârșit */
    }
  }