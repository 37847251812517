
  .animate {
    
    animation:   zoomIn .6s  ease-in;
  }
  
@keyframes zoomIn {
    0% { 
        transform: scale(0);
        opacity: 0;
    }
    100% { 
        transform: scale(1);
        opacity: 1;
    }


}
