 #servicii-flters {
    margin: 0 auto 15px auto;
    list-style: none;
    border-radius: 15px;
    padding: 15px 15px;
    background-color: rgba(3, 3, 3, 0.5);
  }
  
#servicii-flters li {
    cursor: pointer;
    display: flex;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    text-align: center;
    color: black;
    background: rgba(255, 255, 255, 0.5);
    margin: 9px 0;
    transition: all 0.3s ease-in-out;
    border-radius: 4px;
  }

.background{
    background-color: rgba(3, 3, 3, 0.5);
}

.servicii-img{
  filter: drop-shadow(5px 5px 8px var(--main-color));
}
  
 #servicii-flters li:hover,
   #servicii-flters li.filter-active {
    background: #0ef;}
  

    .servicii-flters {
      margin: 0 auto 15px auto;
      list-style: none;
      border-radius: 15px;
      padding: 15px 15px;
      background-color: rgba(3, 3, 3, 0.5);
    }
    
  .servicii-flters li {
      cursor: pointer;
      display: flex;
      padding: 8px 16px;
      font-size: 14px;
      font-weight: 600;
      line-height: 1;
      text-transform: uppercase;
      text-align: center;
      color: black;
      background: rgba(255, 255, 255, 0.5);
      margin: 9px 0;
      transition: all 0.3s ease-in-out;
      border-radius: 4px;
    }

    .my-col {
      margin: 5px 5px; /* Ajustați valoarea pentru spațiul dorit */
  }

  .buton-animat {
    display: inline-block;
    padding: 7px 15px;
    text-decoration: none;
    font-size: 13px;
    position: relative;
}

.buton-animat::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
   
    
    top: 0;
    left: 0;
    z-index: -1;
}

.buton-animat::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #0ef; /* Culoarea punctului */
    border-radius: 50%;
    animation: animatie-punct 3s linear infinite;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: drop-shadow(0px 0px 10px  var(--main-color));
}

@keyframes animatie-punct {
    0% {
        top: 0;
        left: 0;
    }
    25% {
        top: 0;
        left: 100%;
    }
    50% {
        top: 100%;
        left: 100%;
    }
    75% {
        top: 100%;
        left: 0;
    }
    100% {
        top: 0;
        left: 0;
    }
}


