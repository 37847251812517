
.nav {
    position: fixed ;
    top: 0;
    left: 0;
    width: 100%;
    padding: .6rem 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    background: var(--bg-color); 
}
.scrolled {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.6); 
  }
  
a.logo {
    color: var(--text-color);
    margin-top: 5px;
    font-size: 1.8rem;
    color: var(--text-color);
    text-decoration: none;
    font-weight: 600;
    cursor: pointer;
    opacity: 1;
    width: fit-content;
    animation: slideRight 1s ease-in forwards;
    display: flex;
    align-items: center;
    
}

a.logo:hover {
    color: var(--main-color);
}



.navbar {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    width: fit-content;
    margin-bottom: 0;
    
}

.contact-nav{
    border: 1px solid var(--main-color);
    padding: 5px 10px;
    border-radius: 5px;
    color: var(--main-color);
    display: inline-block;
    font-size: 1rem;
    text-decoration: none;
    font-weight: 500;
    opacity: 1;
    animation: slideTop 2s ease;
    animation-delay: calc(.2s * var(--i));
    cursor: pointer;
}

.contact-nav:hover{
    background-color: var(--main-color);
    color: black;
    transition: all 1s ease;
}

 .nav-items {
   display: inline-block;
   font-size: 1rem;
   color: var(--text-color);
   text-decoration: none;
   font-weight: 500;
   margin-left: 0;
   padding-left: 15px;
   padding-right: 15px;
   opacity: 1;
   animation: slideTop 1s ease;
   animation-delay: calc(.2s * var(--i));
   cursor: pointer;
}

.about-nav:hover,
.resume-nav:hover,
.active-nav:hover,
.portfolio-nav:hover {
    color: var(--main-color);
    transition: all 1s ease;
}

.mobile-menu-icon {
    display: none;
}

/* Mobile View */
@media  screen and (max-width:980px) {
.logo {
    display: flex;
    position: relative;
    top:0px;
    left: 0px;
}

.navbar{
   display: none;
}


.mobile-menu-icon {
    display: flex;
    position: relative;
    font-size: 2rem;
    color: var(--text-color);
    background-color: transparent;
    border: none;
    outline: none;

}

.navbar-mobile{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
  list-style: none;
  background-color: var(--second-bg-color);
  left: 0;
  top: 0;
  padding: 0;
  transition: all 0.5s ease-out;
  width: 100%;
  height: 100vh;
}


.about-nav,.resume-nav, .active-nav,.portfolio-nav {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: var(--text-color);
    font-size: 2rem;
    text-decoration: none;
    margin: 1rem;
   
    width: fit-content;
    transition:  all 0.5 ease;
    cursor: pointer;
}
.contact-nav{
    border: 1px solid var(--main-color);
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: var(--main-color);
    font-size: 2rem;
    text-decoration: none;
    transition:  all 0.5 ease;
    margin: 0;
    margin: 1rem;
    cursor: pointer;
}

#cotatie{
    border: 1px solid var(--main-color);
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 2rem;
    text-decoration: none;
    transition:  all 0.5 ease;
   
}

.about-nav:hover,
.resume-nav:hover,
.active-nav:hover,
.portfolio-nav:hover {
    color: var(--main-color);
    transition: all 1s ease;
}
.contact-nav:hover{
    background-color: var(--main-color);
    color: black;
    transition: all 1s ease;
}

}


/* animation key */
@keyframes slideRight {
    0% { 
        transform: translateX(-100px);
        opacity: 0;
    }
    100% { 
        transform: translateX(0);
        opacity: 1;
    }


}
@keyframes slideLeft {
    0% { 
        transform: translateX(100px);
        opacity: 0;
    }
    100% { 
        transform: translateX(0);
        opacity: 1;
    }


}
@keyframes slideTop {
    0% { 
        transform: translateY(100px);
        opacity: 0;
    }
    100% { 
        transform: translateY(0);
        opacity: 1;
    }


}

@keyframes slideBottom {
    0% { 
        transform: translateY(-100px);
        opacity: 0;
    }
    100% { 
        transform: translateY(0);
        opacity: 1;
    }


}
@keyframes zoomIn {
    0% { 
        transform: scale(0);
        opacity: 0;
    }
    100% { 
        transform: scale(1);
        opacity: 1;
    }


}

@keyframes floatImage {
    0% { 
        transform: translateY(0);
        
    }
    50% { 
        transform: translateY(24px);
        
    }
    100% { 
        transform: translateY(0);
        
    }


}
