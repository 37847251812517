*{
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
}

body {
  margin: 0;
  background: var(--bg-color);
  color: var(--text-color);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,600;1,700&display=swap');




:root{
  --bg-color:#1f242d;
  --text-color: #fff;
  --main-color:#0ef;
  --second-bg-color: #323946;
  --white-color:#fdfdfd;
  --shadow-color:rgba(0,0,0,0.2);
}


/*--------------------------------
# Sections General
---------------------------------*/


.section-show {
  top: 100px;
  bottom: auto;
  opacity: 1;
  padding-bottom: 45px;
}

div .container {
  background: transparent;

}



/* Stil pentru indicatorii slide-ului */
.carousel-indicators .active {

  display: none; /* Schimbați culoarea la culoarea "info" dorită */
}

.carousel-indicators{
  display: none;
}

p{
  font-size: 15px;
}

@media (max-width: 768px) {


 .section-show {
    top: 80px;
  }
}

.section-title h2 {
  font-size: 12px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 20px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff;
  font-family: "Poppins", sans-serif;
}

.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #0ef;
  margin: 4px 10px;
}

.section-title p {
  margin: 0;
  margin: -15px 0 15px 0;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #fff;
}


