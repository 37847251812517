.my-modal {
    width: 100%;
    min-height:100vh;
    background-color: black;
    position: absolute;
    top: 0;
    left:0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
  }
  



  .left-button , .right-button {
    background-color: transparent;
    border: none;
    color:white;
    cursor: pointer;
  }

 .body{
    width: 100%;
    display: flex;
    justify-content: end;
    padding-right: 2rem;
    padding-top: 2rem;
    position: absolute;
    top:0;
 }

  .close-modal{
    font-size: 2rem;
   cursor: pointer;
  }
 

  