

.animation{
    animation:   zoomIn 1s  ease-in;
}




.animation1{
    animation:   zoomIn .5s  ease-in;
}
.animation2{
    animation:   zoomIn 1s  ease-in;
}
.animation3{
    animation:   zoomIn 1.5s  ease-in;
}

.slideLeft1{
    animation:   slideLeft .5s  ease-in;
}
.slideLeft2{
    animation:   slideLeft 1s  ease-in;
}

.slideLeft3{
    animation:   slideLeft 1.5s  ease-in;
}







@keyframes zoomIn {
    0% { 
        transform: scale(0);
        opacity: 0;
    }
    100% { 
        transform: scale(1);
        opacity: 1;
    }


}





@keyframes slideLeft {
    0% { 
        transform: translateX(-100px);
        opacity: 0;
    }
    100% { 
        transform: translateX(0);
        opacity: 1;
    }


}