

.name{
    color: var(--main-color);
}

 .name,.job {
    animation: zoomIn 1s ease;
} 

.hero-img{
    filter: drop-shadow(5px 5px 20px var(--main-color));
    animation:   bounce 2s  infinite;
}


 .social-links{
    display: flex;
    justify-content: center;
}  



  @keyframes slideLeft {
    0% { 
        transform: translateX(100px);
        opacity: 0;
    }
    100% { 
        transform: translateX(0);
        opacity: 1;
    }


}

@keyframes zoomIn {
    0% { 
        transform: scale(0);
        opacity: 0;
    }
    100% { 
        transform: scale(1);
        opacity: 1;
    }


}

@keyframes bounce {
  0% {
    transform: translateY(0); 
  }
  50% {
    transform: translateY(-20px);
  }

} 


@media screen and (max-width:900px) {
    .arrow-container {
        display: none;
    }
}


